<template>
  <div class="myBank">
    <div class="bankCon">
      <div>
        <p>{{bankCard}}</p>
        <p>{{phone}}</p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      ListData:'',
    };
  },
  created: function() {
    var _this = this;
     _this.bankCard = _this.$route.query.bankCard;
     _this.phone= _this.$route.query.phone;
  },
  
  methods: {
  }
};
</script>
<style lang="scss" scoped>
.myBank{
  .bankCon{
    div{
      background: orange;
      margin: 1rem;
      border-radius: 0.25rem;
      padding: 2rem;
      color: #fff;
      line-height: 1rem;
    }

  }

}
</style>
